import React, { useState, useRef } from "react"
import Layout from "../../../components/Layout"
import SEO from "../../../components/SEO/SEO"
import NavBar from "../../../components/NavBar/NavBar"
import SeeAlso from "../../../components/SeeAlso/SeeAlso"
import SmallImg from "../../../components/Image/SmallImg"

import {
  Title,
  Select,
  Input,
  Button,
  Field,
  Control,
  Columns,
  Column,
  StaticButton,
} from "../../../StyleComponents/styles"
import { FlexDiv } from "../../../StyleComponents/pagesStyle"

const navRoadArray = [
  { name: "Calculator", link: "/calculator/" },
  {
    name: "Cone Calculator",
    link: "/cone-volume-and-area-calculator/",
  },
]
const seeAlsoArray = [
  "/capsule-volume-and-area-calculator",
  "/conical-frustum-volume-and-area-calculator",
  "/cube-volume-and-area-calculator",
  "/cylinder-volume-and-area-calculator",
  "/ellipsoid-volume-and-area-calculator",
  "/percentage-calculator",
  "/rectangular-volume-and-area-calculator",
  "/sphere-volume-and-area-calculator",
  "/square-pyramid-volume-and-area-calculator",
]

function ConeCalculatorVolumeArea(props) {
  let [result, setresult] = useState("")
  let radius = useRef("")
  let height = useRef("")
  let unitSelector = useRef("")

  function calcVolume(e) {
    let radiusValue = radius.current.value
    let heightValue = height.current.value
    let unit = unitSelector.current.value

    if (radiusValue === "" || isNaN(radiusValue)) {
      setresult(<strong>Please Enter Radius Value </strong>)
    } else if (heightValue === "" || isNaN(heightValue)) {
      setresult(<strong>Please Enter Height Value</strong>)
    } else {
      radiusValue = Number(radiusValue)
      heightValue = Number(heightValue)

      let volume = (1 / 3) * Math.PI * Math.pow(radiusValue, 2) * heightValue

      setresult(
        <span>
          Volume is : {volume.toFixed(2) + unit}
          <sup>3</sup>{" "}
        </span>
      )
    }
  }
  function calcArea(e) {
    let radiusValue = radius.current.value
    let heightValue = height.current.value
    let unit = unitSelector.current.value

    if (radiusValue === "" || isNaN(radiusValue)) {
      setresult(<strong>Please Enter Radius Value </strong>)
    } else if (heightValue === "" || isNaN(heightValue)) {
      setresult(<strong>Please Enter Height Value</strong>)
    } else {
      radiusValue = Number(radiusValue)
      heightValue = Number(heightValue)

      let slant = Math.sqrt(Math.pow(heightValue, 2) + Math.pow(radiusValue, 2))
      let lateralArea = Math.PI * radiusValue * slant
      let surfaceArea =
        Math.PI * radiusValue * slant + Math.PI * Math.pow(radiusValue, 2)
      let basearea = Math.PI * Math.pow(radiusValue, 2)

      setresult(
        <span>
          Total surface area :{surfaceArea.toFixed(2) + unit}
          <sup>2</sup>
          <br />
          Lateral surface : {lateralArea.toFixed(2) + unit}
          <sup>2</sup> <br />
          Base area : {basearea.toFixed(2) + unit}
          <sup>2</sup>
          <br />
          Slant : {slant.toFixed(2) + unit}
        </span>
      )
    }
  }
  return (
    <Layout location={props.location}>
      <SEO
        title="Calculate Volume and Area of Cone - Surface area, lateral Surface, base area, Slant."
        description="Calculator cone volume, surface area, lateral area, base area, calculate cone lateral area, calculate cone surface area, calculate cone base area, calculate cone slant."
        keywords={[
          "Cone Calculator, cone area,calculate Cone Lateral area,calculate Cone Surface area,calculate Cone Base erea, calculate Cone Slant, cone lateral area, cone surface area, cone slant, cone volume, cone calculate volume, cone calculate area, cone calculate slant, cone calculate, calculate cone, slant height cone .",
        ]}
      />
      <NavBar listPages={navRoadArray} />
      <div className="container">
        <Title>Cone Volume and Area Calculator</Title>

        <Columns>
          <Column>
            <Field>
              <Control>
                <StaticButton>Radius</StaticButton>
              </Control>
              <Control className="expandedWidth">
                <Input
                  radius="0"
                  className="borderedRight"
                  type="number"
                  ref={radius}
                />
              </Control>
            </Field>

            <Field>
              <Control>
                <StaticButton>Height</StaticButton>
              </Control>
              <Control className="expandedWidth">
                <Input
                  radius="0"
                  className="borderedRight"
                  type="number"
                  ref={height}
                />
              </Control>
            </Field>

            <Field>
              <Control>
                <StaticButton>Select Unit</StaticButton>
              </Control>
              <Control className="expandedWidth">
                <Select>
                  <select
                    aria-label="unitSelector"
                    className="staticSelect"
                    ref={unitSelector}
                  >
                    <option value="m">m</option>
                    <option value="cm">cm</option>
                    <option value="mm">mm</option>
                    <option value="km">km</option>
                    <option value="dm">dm</option>
                  </select>
                </Select>
              </Control>
            </Field>
            <Button
              borderColor="#1da1f2"
              color="#1da1f2"
              hoverColor="white"
              hoverBorderColor="#1da1f2"
              hoverBackgroundColor="#1da1f2"
              onClick={calcVolume}
            >
              Calculate Volume
            </Button>
            <Button
              borderColor="#1da1f2"
              color="white"
              backgroundColor="#1da1f2"
              hoverColor="#1da1f2"
              hoverBorderColor="#1da1f2"
              onClick={calcArea}
            >
              Calculate area
            </Button>

            <p className="h5 mt-3">{result}</p>
          </Column>
          <Column>
            <FlexDiv maxWidth="250px" margin="auto">
              <SmallImg
                filename="cone.png"
                alt="cone calculator radius and height"
              />
            </FlexDiv>
          </Column>
        </Columns>
        <br />

        <h3>Calculate Cone Volume ?</h3>
        <p>
          The Volume of a Cone is:
          <strong>
            Volume = &pi; * radius<sup>2</sup> * height/3 .
          </strong>
          <br />
          <strong>
            ex ; Volume = &pi; * 10<sup>2</sup> * 20/3 = 2094.395 m<sup>3</sup>
          </strong>
        </p>
        <br />
        <h3>Slant height of a Cone ?</h3>
        <p>
          Slant Cone =
          <strong>
            {" "}
            &radic;
            <span>
              (radius<sup>2</sup> + height<sup>2</sup>)
            </span>
          </strong>
          <br />
          ex : Slant Height = &radic;
          <span>
            10<sup>2</sup>+20<sup>2</sup>
          </span>
          = 22.37m
        </p>

        <br />
        <h3>Cone area ?</h3>
        <p>
          The Lateral Area of a Cone is :
          <strong>
            lateral Surface = &pi; * radius * &radic;
            <span>
              (height<sup>2</sup>+radius<sup>2</sup>)
            </span>
            .<br />
            ex : Lateral area = &pi; * 10 &radic;
            <span>
              20<sup>2</sup>+10<sup>2</sup>
            </span>
            = 702.48 m<sup>2</sup>
          </strong>
          <br />
          The Surface Area of a Cone is :
          <strong>
            Surface area = &pi; * radius * slant + &pi; * radius
            <sup>2</sup>
          </strong>
        </p>
        <br />
        <h3>Base Area of Cone ?</h3>
        <p>
          Base area of a Cone is :
          <strong>
            Base area = &pi; * radius<sup>2</sup>{" "}
          </strong>
          <br />
          ex: Base area = &pi; * 10<sup>2</sup> = 314.16m<sup>2</sup>
        </p>

        <br />
        <SeeAlso seeAlsoList={seeAlsoArray} />
        <br />
      </div>
    </Layout>
  )
}

export default ConeCalculatorVolumeArea
